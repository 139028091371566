import request from '@/utils/http.js'


// 渣土场添加
export function ztcSave(params) {
  return request({
    url: '/ztc/save',
    method: 'post',
    data:params
  })
}

// 渣土场查询
export function ztcQuery(params) {
  return request({
    url: '/ztc/query',
    method: 'get',
    params
  })
}

// 渣土场删除
export function ztcDelete(params) {
  return request({
    url: '/ztc/delete',
    method: 'get',
    params
  })
}


// 土类型添加
export function tlxSave(params) {
  return request({
    url: '/tlx/save',
    method: 'post',
    data:params
  })
}

// 土类型查询
export function tlxQuery(params) {
  return request({
    url: '/tlx/query',
    method: 'get',
    params
  })
}

// 土类型删除
export function tlxDelete(params) {
  return request({
    url: '/tlx/delete',
    method: 'get',
    params
  })
}

// 放行配置管理 编辑
export function passConfigEdit(params) {
  return request({
    url: '/config/pc/edit',
    method: 'get',
    params
  })
}
// 放行配置查询
export function passConfig(params) {
  return request({
    url: '/config/pc/details',
    method: 'get',
    params
  })
}

